.course-list {
  text-align: center;
}

.course-name {
  font-size: 30px;
  color: white;
  text-shadow: 2px 2px 2px black;
}

.course-address {
  font-size:15px;
  margin-top: 10px;
  color: white;
  text-shadow: 1px 1px 1px black;
  margin-bottom:5px;
}

#detail-btn {
  color: white;
  text-shadow: 3px 3px 3px black;
}

#detail-btn:hover {
  color: grey;
}
