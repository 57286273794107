nav {
  background-color: gold;
  height: 100px;
}

.logo {
  width: 100px;
  filter: drop-shadow(5px 3px 4px black)
}

.logo:hover {
  filter: drop-shadow(7px 3px 5px black)
}

.nav-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.golf-bag {
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-courses {
  font-size: 15px;
  grid-column: 3/4;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(1px 1px 1px black);
}

.search {
  border: 1px solid cornflowerblue;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cornflowerblue;
  filter: drop-shadow(1px 1px 1px black);
}

.search:hover {
  filter: drop-shadow(2px 2px 2px black);
}

@media only screen and (max-width: 476px) {
 .nav-wrapper {
   display: grid;
   grid-template-columns: 1fr 1fr;
 }

 .golf-bag {
   grid-column: 1/2;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .search-courses {
   grid-column: 2/3;
   display: flex;
   justify-content: center;
   align-items: center;
 }

}