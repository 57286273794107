.details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-name {
  text-align: center;
  color: white;
}

.course-detail-address {
  text-align: center;
  text-shadow: 2px 2px 2px black;
  font-size: 30px;
}

.course-phone {
  text-align: center;
  color: white;
  text-shadow: 2px 2px 2px black;
}

.course-rating {
  text-align: center;
  color: white;
  text-shadow: 2px 2px 2px black;
  margin-bottom: 20px;
}

.tee-time {
  color: white;
  text-shadow: 2px 2px 2px black;
}